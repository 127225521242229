import React from "react"

import SEO from "../components/seo"
import BaseLayout from "../components/layout/baseLayout"
import Footer from "../components/landing/Footer"
import Header from "../components/landing/Header"
import { Logo } from "../images/Logo"
import { Link } from "gatsby"

const NotFoundPage = () => (
  <div className="bg-gray-900">
    <SEO title="Not Found" />
    <BaseLayout>
      <div className="min-h-screen pb-12 flex flex-col bg-white">
        <Header />
        <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex-shrink-0 flex justify-center">
            <a href="/" className="inline-flex">
              {/* <span className="sr-only"></span> */}
              <Logo />
            </a>
          </div>
          <div className="py-16">
            <div className="text-center">
              <p className="text-sm font-semibold text-cyan-600 uppercase tracking-wide">
                404 error
              </p>
              <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                Page not found.
              </h1>
              <p className="mt-2 text-base text-gray-500">
                Sorry, we couldn’t find the page you’re looking for.
              </p>
              <div className="mt-6">
                <Link
                  to="/"
                  className="text-base font-medium text-cyan-600 hover:text-cyan-500"
                >
                  Go back home<span aria-hidden="true"> &rarr;</span>
                </Link>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </BaseLayout>
  </div>
)

export default NotFoundPage
